import React from "react";
import styles from "./styles.module.css";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

import {
  selectVehicleById,
  selectFaultsAsArray,
  selectCategoriesAsArray,
  selectReportTypesAsArray,
  selectActionsAsArray,
  selectReportById,
  putReport,
  selectToken,
  postReport,
  selectUser,
  selectUsersAsArray,
  fetchReports,
  selectPostedId,
  removePosted,
  selectPostingReportStatus,
} from "common/ducks/api";
import TopNav from "../topnav";
import Inspect from "../inspect";
import {
  selectCurrentReport,
  cancelReport,
  openReport,
  saveReport,
  deleteMyReport,
  duplicateReport,
  createReport,
} from "common/ducks/report";
import { withRouter, Redirect } from "react-router-dom";
import { selectMyVehicleById } from "common/ducks/vehicle";
import {
  mergeApiItemsWithMyItemsAsArray,
  myReportToApiPayload,
} from "common/services/api";
import { createDefault, selectDefaultAsArray } from "common/ducks/defaults";
import Printable from "../printable";
import InspectionForm from "../inspectionform";

class Report extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInspecting: true,
      isEditing: false,
      report: {},
      isSaving: false,
    };
  }

  componentDidMount() {
    this.openOrCreate();
  }

  componentWillUnmount() {
    if (this.props.current_report) {
      this.props.cancelReport({
        id: this.props.current_report.id,
        vehicle: this.props.vehicleID,
      });
    }
  }

  create = async () => {
    this.props.createReport({
      vehicle: this.props.vehicleID,
    });
  };

  open = async (id = this.props.reportID, report = this.props.report) => {
    if (id < 0) {
      this.props.openReport({
        id,
        vehicle: this.props.vehicleID,
      });
    } else {
      this.props.duplicateReport({
        id,
        report,
        vehicle: this.props.vehicleID,
      });
    }
  };

  openOrCreate = async () => {
    this.props.reportID === "new" ? this.create() : this.open();
  };

  save = async () => {
    // Save locally and open local report
    this.setState({ isSaving: true });
    const myReportsId = this.props.current_report.id;

    await this.props.saveReport({
      report: this.state.report,
      id: this.props.current_report.id,
      vehicle: this.props.vehicleID,
    });
    this.setState({ isEditing: false });
    this.open(this.props.current_report.id);

    // Save remotely and open remote report
    if (this.props.current_report.id >= 0) {
      await this.props.putReport({
        token: this.props.token,
        report: myReportToApiPayload({ report: this.state.report }),
        myReportId: this.props.current_report.id,
      });
      await this.props.getReports({
        token: this.props.token,
        last_updated: this.props.last_updated,
        vehicle: this.props.vehicleID,
      });
      this.open(this.props.current_report.id, this.props.postedReportData);
    } else {
      await this.props.postReport({
        token: this.props.token,
        report: myReportToApiPayload({ report: this.state.report }),
        myReportId: this.props.current_report.id,
      });

      // Stop if post not successful
      if (this.props.sendingStatus?.error) {
        console.log("ERROR POSTING");
      } else {
        await this.props.getReports({
          token: this.props.token,
          last_updated: this.props.last_updated,
          vehicle: this.props.vehicleID,
        });
        await this.open(this.props.postedId, this.props.postedReportData);
        this.props.removePosted(
          `fleet_viper/api/POST_REPORT/${this.props.vehicleID}/${myReportsId}`
        );
      }
    }

    this.setState({ isSaving: false });
  };

  cancel = () => {
    this.props.cancelReport({
      id: this.props.reportID,
      vehicle: this.props.vehicleID,
    });
    this.setState({ isInspecting: false });
  };

  handleChange = ({ report, isEditing }) => {
    this.setState({ report: report, isEditing: isEditing });
  };

  render() {
    if (this.props.current_report) {
      return (
        <div className={styles.container}>
          {this.props.sendingStatus?.error && (
            <div className={styles.error}>Error sending report</div>
          )}
          {this.state.isSaving && (
            <TopNav
              title={this.props.vehicle.reg}
              backTo={`/vehicles/${this.props.vehicle.id}`}
            >
              SAVING...
            </TopNav>
          )}

          {!this.state.isSaving && this.state.isEditing && (
            <TopNav
              title={this.props.vehicle.reg}
              backTo={`/vehicles/${this.props.vehicle.id}`}
            >
              <Button data-cy="cancel" onClick={this.cancel} color="inherit">
                CANCEL
              </Button>
              <Button data-cy="save" onClick={this.save} color="inherit">
                SAVE
              </Button>
            </TopNav>
          )}

          {!this.state.isSaving && !this.state.isEditing && (
            <TopNav
              title={this.props.vehicle.reg}
              backTo={`/vehicles/${this.props.vehicle.id}`}
            >
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <Button color="inherit">
                      <div data-cy="print" style={{ display: "flex" }}>
                        <PrintIcon />
                      </div>
                    </Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </TopNav>
          )}

          <div className={styles.cardscontainer}>
            <Printable ref={(el) => (this.componentRef = el)}>
              <InspectionForm
                report={{
                  ...this.props.current_report.data,
                }}
                vehicle={this.props.vehicle}
              />
            </Printable>
            <Inspect
              actions={this.props.actions}
              createDefault={this.props.createDefault}
              faults={this.props.faults}
              onChange={this.handleChange}
              onSave={this.save}
              onCancel={this.cancel}
              report={{
                ...this.props.current_report.data,
              }}
              reportTypes={this.props.reportTypes}
              user={this.props.user}
              users={this.props.users}
              vehicle={this.props.vehicleID}
            />
          </div>
        </div>
      );
    } else if (this.state.isInspecting) {
      return <LinearProgress />;
    } else {
      return <Redirect to="/reports" />;
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    cancelReport: ({ vehicle, id }) => {
      dispatch(cancelReport({ vehicle, id }));
    },
    saveReport: ({ report, id, vehicle }) => {
      dispatch(saveReport({ report, id, vehicle }));
    },
    openReport: async ({ id, vehicle }) => {
      await dispatch(openReport({ id, vehicle }));
    },
    createReport: ({ id, vehicle }) => {
      dispatch(createReport({ vehicle }));
    },
    deleteReport: ({ id, vehicle }) => {
      dispatch(deleteMyReport({ id, vehicle }));
    },
    duplicateReport: ({ id, report, vehicle }) => {
      dispatch(duplicateReport({ id, report, vehicle }));
    },
    putReport: async ({ token, report, myReportId }) => {
      await dispatch(putReport({ token, report, myReportId }));
    },
    postReport: async ({ token, report, myReportId }) => {
      await dispatch(postReport({ token, report, myReportId }));
    },
    createDefault: ({ my_default, data }) => {
      dispatch(createDefault({ my_default, data }));
    },
    getReports: async ({ token, last_updated, vehicle }) => {
      if (vehicle !== "new") {
        await dispatch(
          fetchReports({ token, last_updated, vehicle, page_size: 1000 })
        );
      }
    },
    removePosted: (label) => {
      dispatch(removePosted(label));
    },
  };
};
const mapStateToProps = (state, ownProps) => {
  let vehicle =
    ownProps.vehicleID < 0
      ? selectMyVehicleById(state, ownProps.vehicleID)
      : selectVehicleById(state, ownProps.vehicleID);

  // If duplicating report from API server
  let report;
  if (ownProps.reportID >= 0) {
    report = selectReportById(state, ownProps.vehicleID, ownProps.reportID);
  } else {
    report = null;
  }

  let postedId;
  let postedReportData;

  try {
    postedId = selectPostedId(
      state,
      `fleet_viper/api/POST_REPORT/${ownProps.vehicleID}/${
        selectCurrentReport(state)?.id
      }`
    );
  } catch (err) {
    console.log(`err: ${err}`);
  }

  try {
    postedReportData = selectReportById(state, ownProps.vehicleID, postedId);
  } catch (err) {
    console.log(`err: ${err}`);
  }

  return {
    vehicle: vehicle,
    report: report,
    current_report: selectCurrentReport(state),
    faults: selectFaultsAsArray(state),
    actions: mergeApiItemsWithMyItemsAsArray({
      apiItems: selectActionsAsArray(state),
      myItems: selectDefaultAsArray({ state, my_default: "my_actions" }),
    }),
    reportTypes: mergeApiItemsWithMyItemsAsArray({
      apiItems: selectReportTypesAsArray(state),
      myItems: selectDefaultAsArray({ state, my_default: "my_reporttypes" }),
    }),
    sendingStatus: selectPostingReportStatus(
      state,
      vehicle.id,
      selectCurrentReport(state)?.id
    ),
    token: selectToken(state),
    user: selectUser(state),
    users: selectUsersAsArray(state),
    postedReportData: postedReportData,
    postedId: postedId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));
