import fetch from "node-fetch";

import { reportSearchFilter } from "../modules/search-filters";
import defaultStates from "../modules/default-states";
import { dateToYYYYMMDD } from "../modules/date-utils";
import config from "./config";

export const URL = config.api.URL;

export function apiVehicleToAppVehicle(vehicle) {
  const vehicleCleaned = { ...vehicle };
  delete vehicleCleaned["id"];
  // @TODO these hidden items should be settings on the account which can be hidden or shown
  // delete vehicleCleaned["body_type"];
  // delete vehicleCleaned["chassis_no"];
  // delete vehicleCleaned["fleet_no"];
  delete vehicleCleaned["company"];
  delete vehicleCleaned["deleted"];
  delete vehicleCleaned["last_updated"];
  delete vehicleCleaned["last_six_weekly"];
  return vehicleCleaned;
}

export function apiReportToAppReport(report) {
  const app_report = {
    id: report.id,
    checkedInfo: {
      checker: report.checker || defaultStates.CHECKED_INFO.checker,
      checkedDate: {
        date:
          report.checked_date || defaultStates.CHECKED_INFO.checkedDate.date,
      },
    },
    reportInfo: {
      reportType:
        (report && report.report_type) || defaultStates.REPORT_INFO.report_type,
      inspectionDate: {
        date:
          (report &&
            report.inspection_date &&
            new Date(report.inspection_date)) ||
          defaultStates.REPORT_INFO.inspectionDate.date,
      },
      inspector:
        (report && report.inspector) || defaultStates.REPORT_INFO.inspector,
    },
    vehicleInfo: {
      odometer: {
        reading:
          (report && report.odometer && report.odometer.km) ||
          defaultStates.VEHICLE_INFO.odometer.reading,
        unit: defaultStates.VEHICLE_INFO.odometer.unit,
      },
      tachograph: {
        date:
          (report &&
            report.tachograph &&
            new Date(report.tachograph.next_calibration_date)) ||
          defaultStates.VEHICLE_INFO.tachograph.date,
        expanded: false,
      },
      vehicle: (report && report.vehicle) || defaultStates.VEHICLE_INFO.vehicle,
    },
    rectifications: (report && report.rectifications) || [],
    checks: (report && report.checks) || [],
    tyres: (report && report.tyres) || [],
    parts: (report && report.parts) || [],
    brakeTest: report?.brake_test,
  };

  return app_report;
}

export function apiReportsToAppReportsArray(reports) {
  let reportsObject = {};
  reports.map((report) => {
    if (!reportsObject[report["vehicle"]]) {
      reportsObject[report["vehicle"]] = [];
    }
    reportsObject[report["vehicle"]].push(report);
  });
  return reportsObject;
}

export function apiReportsToAppReports(prev, reportsResponse, pending = false) {
  let reports = sortReports(reportsResponse["results"]);
  if (Object.keys(prev).length === 0) {
    return replaceApiReports(reports, reportsResponse["next"], pending);
  } else {
    return mergeApiReports(prev, reports, reportsResponse["next"], pending);
  }
}

function sortReports(reports) {
  let arr = [...reports];
  return arr.sort((a, b) => a.inspection_date - b.inspection_date);
}

export function replaceApiReports(reports, next, pending = false) {
  let results = {};
  if (pending) {
    results["downloaded"] = new Date().toISOString();
  }
  reports.map((report) => {
    const vehicleID = report.vehicle;
    const reportID = report.id;

    if (!results[vehicleID]) {
      results[vehicleID] = {};
      results[vehicleID].reports = {};
      results[vehicleID].order = [];
    }
    results[vehicleID].reports[reportID] = report;
    results[vehicleID].next = next;
    results[vehicleID].downloaded = new Date().toISOString();
    results[vehicleID].order.push(reportID);
  });
  return results;
}

export function apiVehiclesToAppVehicles(prev, vehiclesResponse) {
  let vehicles = vehiclesResponse["results"];
  if (Object.keys(prev).length === 0) {
    return replaceApiVehicles(vehicles);
  } else {
    return mergeApiVehicles(prev, vehicles);
  }
}

export function replaceApiVehicles(vehicles) {
  let results = { vehicles: {} };
  vehicles.map((vehicle) => {
    if (!results.vehicles[vehicle.id]) {
      results.vehicles[vehicle.id] = {};
    }
    results.vehicles[vehicle.id] = vehicle;
    results.downloaded = new Date().toISOString();
  });
  return results;
}

export function mergeApiVehicles(prev, vehicles) {
  let results = { ...prev };

  vehicles.map((vehicle) => {
    // If new vehicle created in DB since last download
    if (!results.vehicles[vehicle.id]) {
      results.vehicles[vehicle.id] = {};
    }
    // If vehicle deleted in DB since last download
    if (vehicle.deleted) {
      delete results.vehicles[vehicle.id];
    } else {
      results.vehicles[vehicle.id] = vehicle;
    }
    // @TODO Need a buffer of time as new DB activity could have happened since this function call!
    results.downloaded = new Date().toISOString();
  });
  return results;
}

export function mergeApiItemsWithMyItems({ apiItems, myItems }) {
  let mergedItems = { ...apiItems };

  myItems.map((item) => {
    mergedItems[item.id] = item;
  });
  return mergedItems;
}

export function mergeApiItemsWithMyItemsAsArray({ apiItems, myItems }) {
  let mergedItems = mergeApiItemsWithMyItems({ apiItems, myItems });
  const mergedItemsArray = Object.keys(mergedItems).map(
    (id) => mergedItems[id]
  );
  return mergedItemsArray;
}

export function mergeApiReports(prev, reports, next, pending) {
  let results = { ...prev };
  if (pending) {
    results["downloaded"] = new Date().toISOString();
  }

  reports.map((report) => {
    const vehicleID = report.vehicle;
    const reportID = report.id;

    if (!results[vehicleID]) {
      results[vehicleID] = {};
      results[vehicleID].reports = {};
      results[vehicleID].order = [];
    }
    // Remove reportID from order if it exists
    if (results[vehicleID].reports[reportID]) {
      const index = results[vehicleID].order.indexOf(reportID);
      results[vehicleID].order.splice(index, 1);
    }

    if (report.deleted) {
      delete results[vehicleID].reports[reportID];
    } else {
      results[vehicleID].reports[reportID] = report;
      results[vehicleID].order.splice(
        findReportLocation(
          report,
          results[vehicleID].reports,
          results[vehicleID].order,
          "inspection_date"
        ),
        0,
        reportID
      );
    }

    results[vehicleID].downloaded = new Date().toISOString();
    results[vehicleID].next = next;

    // Delete vehicle key from report state
    if (results[vehicleID].order.length === 0) {
      delete results[vehicleID];
    }
  });
  return results;
}

export function findReportLocation(report, reports, order, property) {
  let index = order.findIndex((id) => report[property] > reports[id][property]);
  return index === -1 ? order.length : index;
}

export function apiFaultsToAppFaults(prev, faults) {
  // inputs:  prev = state
  //          faults = Array of faults
  // output:  {faults: {<REPORTID>: {}},
  //           order: [<REPORTID>],
  //           downloaded: <DATE>}
  let results = { ...prev };
  faults.map((fault) => {
    const faultID = fault.id;
    // Push faultID to order if it doesn't exist
    if (!results.faults[faultID]) {
      results.order.push(faultID);
    }
    results.faults[faultID] = { fault };
    results.downloaded = new Date().toISOString();
  });
  return results;
}

export function apiToApp(prev, array, label) {
  let results = { ...prev };
  array.map((obj) => {
    if (!results[label][obj.id]) {
      results.order.push(obj.id);
    }
    results[label][obj.id] = obj;
    results.downloaded = new Date().toISOString();
  });
  return results;
}

export const POST_REQUEST_OPTIONS = {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const REQUEST_TOKEN_URL = `${URL}/api-token-auth/`;

export async function requestToken({ username, password }) {
  const REQUEST_TOKEN_OPTIONS = {
    ...POST_REQUEST_OPTIONS,
    body: JSON.stringify({ username, password }),
  };
  try {
    console.log(REQUEST_TOKEN_URL);
    const rawResponse = await fetch(REQUEST_TOKEN_URL, REQUEST_TOKEN_OPTIONS);
    const token = await rawResponse.json();
    return token["token"];
  } catch (err) {
    throw "Could not fetch token";
  }
}

export async function get({ url, token }) {
  try {
    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (rawResponse.status !== 200) throw "GET not successful";
    const response = await rawResponse.json();
    return response;
  } catch (err) {
    return { error: err, route: `/${url}` };
  }
}

export async function deleteRequest({ url, token }) {
  try {
    const rawResponse = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (rawResponse.status !== 204) throw "DELETE not successful";
    const response = true;
    return response;
  } catch (err) {
    return { error: err, route: `/${url}` };
  }
}

export async function post({ url, body, token }) {
  try {
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (rawResponse.status !== 201) throw "POST not successful";
    const response = await rawResponse.json();
    return response;
  } catch (err) {
    return { error: err, route: `/${url}` };
  }
}

export async function put({ url, body, token }) {
  try {
    const rawResponse = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (rawResponse.status !== 200) throw "PUT not successful";
    const response = await rawResponse.json();
    return response;
  } catch (err) {
    return { error: err, route: `/${url}` };
  }
}

export function myVehicleToApiPayload({ vehicle }) {
  let payload = { ...vehicle };
  return payload;
}

export function myReportToApiPayload({ report }) {
  let payload = {};
  if (report.checkedInfo?.checker?.id) {
    payload["checker"] = report.checkedInfo.checker.id;
    payload["checked_date"] = dateToYYYYMMDD(
      report.checkedInfo.checkedDate.date
    );
  }
  payload["inspector"] = report.reportInfo.inspector.id;
  payload["inspection_date"] = dateToYYYYMMDD(
    report.reportInfo.inspectionDate.date
  );

  payload["report_type"] = report.reportInfo.reportType.id;
  payload["vehicle"] = report.vehicleInfo.vehicle;

  if (report.vehicleInfo.odometer.unit === "miles") {
    payload["odometer"] = {
      km: parseInt(report.vehicleInfo.odometer.reading * 1.6093),
    };
  } else {
    payload["odometer"] = {
      km: parseInt(report.vehicleInfo.odometer.reading),
    };
    payload["odometer"]["vehicle"] = report.vehicle;
  }

  payload["tachograph"] = {
    next_calibration_date: dateToYYYYMMDD(report.vehicleInfo.tachograph.date),
    vehicle: report.vehicle,
  };

  payload["rectifications"] = [];
  Object.keys(report.rectifications).forEach((rectificationId) => {
    const rectification = {
      action: report.rectifications[rectificationId].action.id,
      fault: report.rectifications[rectificationId].fault.id,
      rectifier: report.rectifications[rectificationId].rectifier.id,
      description: report.rectifications[rectificationId].description,
    };
    if (report.rectifications[rectificationId].warranty) {
      rectification["warranty"] = {
        description:
          report.rectifications[rectificationId].warranty.description,
        warranty_expiry: dateToYYYYMMDD(
          report.rectifications[rectificationId].warranty.warranty_expiry
        ),
      };
    }
    payload["rectifications"].push(rectification);
  });
  payload["checks"] = report.checks;
  payload["tyres"] = report.tyres;
  payload["parts"] = report.parts?.map((part) => ({
    part_number: part.part_number,
    description: part.description,
    nett: part.nett,
    quantity: part.quantity,
    date_fitted: payload["inspection_date"],
  }));
  payload["brake_test"] = report.brakeTest;
  return payload;
}

export function reportToInspectionForm({ report }) {
  let payload = {};
  if (report.checkedInfo?.checker?.id) {
    payload["checker"] = report.checkedInfo.checker;
    payload["checked_date"] = dateToYYYYMMDD(
      report.checkedInfo.checkedDate.date
    );
  }
  payload["inspector"] = report.reportInfo.inspector;
  payload["inspection_date"] = dateToYYYYMMDD(
    report.reportInfo.inspectionDate.date
  );

  payload["report_type"] = report.reportInfo.reportType;
  payload["vehicle"] = report.vehicleInfo.vehicle;

  if (report.vehicleInfo.odometer.unit === "miles") {
    payload["odometer"] = {
      km: parseInt(report.vehicleInfo.odometer.reading * 1.6093),
    };
  } else {
    payload["odometer"] = {
      km: parseInt(report.vehicleInfo.odometer.reading),
    };
    payload["odometer"]["vehicle"] = report.vehicle;
  }

  payload["tachograph"] = {
    next_calibration_date: dateToYYYYMMDD(report.vehicleInfo.tachograph.date),
    vehicle: report.vehicle,
  };

  payload["rectifications"] = report.rectifications;
  payload["checks"] = report.checks;
  payload["tyres"] = report.tyres;
  payload["brake_test"] = report.brakeTest;
  return payload;
}
