import React from "react";
import styles from "./styles.module.css";
import { BRAKE_TEST_DEFAULT_STATE } from "common/modules/default-states";

const BrakeTestComponent = ({ brakeTest }) => {
  const test = brakeTest || BRAKE_TEST_DEFAULT_STATE;

  return (
    <div className={styles.reportContainer}>
      <div className={styles.performanceSectionRow}>
        <div className={styles.imsTitle}>
          <div>BRAKE PERFORMANCE</div>
          <div>IM Nos 71, 72, 73</div>
        </div>

        <div className={styles.roadSpeedLimiter}>
          <span>ROAD SPEED LIMITER operational </span>
          <span>{test.road_speed_limiter_operational ? "YES" : "NO"}</span>
          <span>- set speed: </span>
          <span>{test.road_speed_limiter_speed_kph} kph</span>
        </div>

        <div className={styles.loadConditionTitle}>
          Load condition of vehicle
        </div>
      </div>
      <div className={styles.performanceSectionRow}>
        <div className={styles.decelerometer}>
          <div>
            <span>Decelerometer test </span>
            <span>{test.decelerometer_test_completed ? "YES" : "NO"}</span>
          </div>
          <div>
            <span>@ {test.decelerometer_test_kph} kph</span>
          </div>
        </div>
        <div className={styles.roadConditions}>
          <div>
            <span>Road conditions test </span>
            <span>{test.road_conditions}</span>
          </div>
        </div>
        <div className={styles.loadCondition}>
          <div>
            <span>{test.load_condition}</span>
          </div>
        </div>
      </div>

      <div className={styles.performanceSectionRow}>
        <div className={styles.rollerBrake}>
          <div>
            <span>Roller brake test </span>
            <span>{test.roller_brake_test_completed ? "YES" : "NO"}</span>
          </div>
        </div>
        <div className={styles.im}>
          <div>Service IM no 71</div>
          <div>{test.service_brake_performance} %</div>
        </div>
        <div className={styles.im}>
          <div>Secondary IM no 72</div>
          <div>{test.secondary_brake_performance} %</div>
        </div>
        <div className={styles.im}>
          <div>Parking IM no 73</div>
          <div>{test.parking_brake_performance} %</div>
        </div>
        <div className={styles.imComments}>
          <div>Comments</div>
          <div>{test.performance_comments}</div>
        </div>
      </div>

      <div className={styles.temperatureSection}>
        <div className={styles.temperatureTitle}>
          BRAKE TEMPERATURE ASSESSMENT
        </div>
        <div className={styles.temperatureGrid}>
          <div className={styles.gridHeader}>
            <span>Side</span>
            <span>Axle 1</span>
            <span>Axle 2</span>
            <span>Axle 3</span>
            <span>Axle 4</span>
            <span>Side</span>
            <span>Axle 1</span>
            <span>Axle 2</span>
            <span>Axle 3</span>
            <span>Axle 4</span>
          </div>
          <div className={styles.gridRow}>
            <span>O/S</span>
            <span>{test.brake_temperature_os_1} °C</span>
            <span>{test.brake_temperature_os_2} °C</span>
            <span>{test.brake_temperature_os_3} °C</span>
            <span>{test.brake_temperature_os_4} °C</span>
            <span>N/S</span>
            <span>{test.brake_temperature_ns_1} °C</span>
            <span>{test.brake_temperature_ns_2} °C</span>
            <span>{test.brake_temperature_ns_3} °C</span>
            <span>{test.brake_temperature_ns_4} °C</span>
          </div>
          <div className={styles.gridRow}>
            <span className={styles.dateAssessedLabel}>
              Date of braking assessment:
            </span>
            <span className={styles.dateAssessedValue}>
              {test.date_assessed?.date?.toDateString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrakeTestComponent;
