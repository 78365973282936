import React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Card, CardHeader } from "@material-ui/core";
import InputDate from "../inputdate";

const colors = MyTheme.colors;

const BrakeTestComponent = ({
  brakeTest,
  current,
  handleChange,
  setFunction,
}) => {
  // Helper to handle input changes
  const commonProps = (key) => ({
    onChange: (event) => {
      let value = event.target.value;

      // Convert numeric fields
      if (
        [
          "road_speed_limiter_speed_kph",
          "service_brake_performance",
          "secondary_brake_performance",
          "parking_brake_performance",
          "decelerometer_test_kph",
          "brake_temperature_os_1",
          "brake_temperature_os_2",
          "brake_temperature_os_3",
          "brake_temperature_os_4",
          "brake_temperature_ns_1",
          "brake_temperature_ns_2",
          "brake_temperature_ns_3",
          "brake_temperature_ns_4",
        ].includes(key)
      ) {
        value = value === "" ? "" : Number(value);
      }

      // Convert boolean fields
      if (
        [
          "road_speed_limiter_operational",
          "decelerometer_test_completed",
          "roller_brake_test_completed",
        ].includes(key)
      ) {
        value = value === "true";
      }

      let isEditing = current ? value !== current[key] : true;
      handleChange(
        key,
        () => setFunction({ ...brakeTest, [key]: value }),
        isEditing
      );
    },
    value: brakeTest?.[key]?.toString() || "",
  });

  return (
    <div>
      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Road Speed Limiter"
        />
        <div className={styles.row}>
          <div className={styles.column2}>
            <TextField
              className={styles.input}
              margin="normal"
              variant="outlined"
              label="Operational"
              data-cy="road_speed_limiter_operational"
              select
              {...commonProps("road_speed_limiter_operational")}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </div>
          <div className={styles.column2}>
            <TextField
              className={styles.input}
              margin="normal"
              variant="outlined"
              label="Speed (kph)"
              data-cy="road_speed_limiter_speed_kph"
              type="number"
              {...commonProps("road_speed_limiter_speed_kph")}
            />
          </div>
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Road Conditions"
        />
        <div className={styles.row}>
          <TextField
            className={styles.input}
            margin="normal"
            variant="outlined"
            label="Description"
            data-cy="road_conditions"
            {...commonProps("road_conditions")}
          />
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Load Condition of Vehicle"
        />
        <div className={styles.row}>
          <TextField
            className={styles.input}
            margin="normal"
            variant="outlined"
            label="Load Condition"
            data-cy="load_condition"
            select
            {...commonProps("load_condition")}
          >
            <MenuItem value="laden">Laden</MenuItem>
            <MenuItem value="unladen">Unladen</MenuItem>
          </TextField>
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Brake Performance"
        />
        <div className={styles.row}>
          <div className={styles.column3}>
            <TextField
              className={styles.input}
              margin="normal"
              variant="outlined"
              label="Service %"
              data-cy="service_brake_performance"
              {...commonProps("service_brake_performance")}
            />
          </div>
          <div className={styles.column3}>
            <TextField
              className={styles.input}
              label="Secondary %"
              margin="normal"
              variant="outlined"
              data-cy="secondary_brake_performance"
              {...commonProps("secondary_brake_performance")}
            />
          </div>
          <div className={styles.column3}>
            <TextField
              className={styles.input}
              label="Parking %"
              margin="normal"
              variant="outlined"
              data-cy="parking_brake_performance"
              {...commonProps("parking_brake_performance")}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TextField
            className={styles.input}
            style={styles.loadComments}
            key="performance_comments"
            margin="normal"
            variant="outlined"
            label="Comments"
            data-cy="performance_comments"
            {...commonProps("performance_comments")}
          />
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Decelerometer/Roller Brake Test"
        />
        <div className={styles.row}>
          <div className={styles.column2}>
            <TextField
              className={styles.input}
              label="Test Completed"
              margin="normal"
              variant="outlined"
              select
              {...commonProps("decelerometer_test_completed")}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </div>
          <div className={styles.column2}>
            <TextField
              className={styles.input}
              label="Speed (kph)"
              margin="normal"
              variant="outlined"
              data-cy="decelerometer_test_kph"
              type="number"
              {...commonProps("decelerometer_test_kph")}
            />
          </div>
        </div>

        <div className={styles.row}>
          <TextField
            className={styles.input}
            key="roller_brake_test_completed"
            margin="normal"
            variant="outlined"
            label="Roller Brake Test Completed"
            data-cy="roller_brake_test_completed"
            select
            {...commonProps("roller_brake_test_completed")}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Road Test"
        ></CardHeader>
        <div className={styles.row}>
          <TextField
            className={styles.input}
            key="overall_comments"
            margin="normal"
            variant="outlined"
            label="Overall Comments"
            data-cy="overall_comments"
            multiline
            rows={4}
            value={brakeTest?.overall_comments}
            {...commonProps("overall_comments")}
          />
        </div>
      </Card>

      <Card
        className={styles.card}
        variant="outlined"
        style={{ backgroundColor: colors.secondaryCardLight }}
      >
        <CardHeader
          titleTypographyProps={{ style: { fontSize: "1em" } }}
          title="Brake Temperatures"
        />
        <div className={styles.tempsOuterContainer}>
          <div className={styles.osTemps}>
            {[1, 2, 3, 4].map((num) => (
              <TextField
                className={styles.input}
                key={`brake_temperature_os_${num}`}
                label={`OS Axel ${num} (°C)`}
                margin="normal"
                variant="outlined"
                type="number"
                {...commonProps(`brake_temperature_os_${num}`)}
              />
            ))}
          </div>
          <div className={styles.nsTemps}>
            {[1, 2, 3, 4].map((num) => (
              <TextField
                className={styles.input}
                key={`brake_temperature_ns_${num}`}
                label={`NS Axel ${num} (°C)`}
                margin="normal"
                variant="outlined"
                type="number"
                {...commonProps(`brake_temperature_ns_${num}`)}
              />
            ))}
          </div>
        </div>

        <div className={styles.row}>
          <InputDate
            dataCy="brake-test-date"
            label="Date Assessed"
            value={brakeTest?.date_assessed}
            onChange={(value) =>
              setFunction({ ...brakeTest, date_assessed: value })
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default React.memo(BrakeTestComponent);
